import { authActions } from "../state/features/authReducer";
import { cellListActions } from "../state/features/cellListReducer";
import { facilityListActions } from "../state/features/facilityListReducer";
import { userSelectionActions } from "../state/features/userSelectionReducer";

const useAppLogout = () => {
    const logout = (dispatch) => {
        dispatch(authActions.logout());
        dispatch(cellListActions.logout());
        dispatch(facilityListActions.logout());
        dispatch(userSelectionActions.logout());
    };

    return logout;
};

export default useAppLogout;