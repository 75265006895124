import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import { useLocation } from 'react-router-dom';

const NavbarContainer = ({ onCollapseMenu = () => {}, visibleSideMenu, mobile, mobileCollapse }) => {
    const location = useLocation();
    const defaultMenu = 'dashboard';
    const selectedKeyArr = location?.pathname?.split('/') ?? [];
    const selectedPath = location?.pathname.slice(location?.pathname.indexOf('/') + 1) || defaultMenu;
    let selectedKey = selectedKeyArr?.length > 2 ? selectedPath : selectedKeyArr?.[1] || defaultMenu;
    const lpcPerformanceDetailsRoute = 'ml_admin/lcp_performance_details';
    const initialInterval = 10;

    const parentRouteMapping = {
        'ml_admin/lcp_performance_details': 'ml_admin/lcp_performance',
    };

    if (selectedKey === lpcPerformanceDetailsRoute) {
        selectedKey = parentRouteMapping?.[selectedKey];
    }

    const mainMenu = selectedKeyArr?.[1] || defaultMenu;
    const [openKeys, setOpenKeys] = useState([mainMenu]);

    const onSideMenuVisiblityChange = (openMenuKeys, interval) => {
        setTimeout(() => {
            setOpenKeys(openMenuKeys);
        }, interval);
    };

    useEffect(() => {
        if (!visibleSideMenu && !mobileCollapse) {
            onSideMenuVisiblityChange([mainMenu], initialInterval);
        } else {
            onSideMenuVisiblityChange([], initialInterval + 1);
        }
    }, [visibleSideMenu, mobileCollapse]);

    const closeMenu = () => {
        setOpenKeys([]);
    };
    
    const onExpandMenu = (keys) => {
        if (keys?.length > 0) {
            setOpenKeys([keys?.[keys?.length - 1]]);
        } else {
            closeMenu();
        }
    };

    return (
        <Navbar
            onExpandMenu={onExpandMenu}
            openKeys={openKeys}
            visibleSideMenu={visibleSideMenu}
            mobileCollapse={mobileCollapse}
            mobile={mobile}
            onCollapseMenu={onCollapseMenu}
            selectedKey={selectedKey}
            closeMenu={closeMenu}
        />
    );
};

export default NavbarContainer;
