import React, { lazy } from "react";

const MlConfiguration = lazy(() => import("./ml-configuration"));
const RulesConfiguration = lazy(() => import("./rules-configuration"));
const AlertsConfiguration = lazy(() => import("./alerts-configuration"));
const StateConfiguration = lazy(() => import("./state-configuration"));

const ControlsRoute = () => {
    const routes = [
        {
            key: "rules_configuration",
            path: "/controls_admin/rules_configuration",
            component: <RulesConfiguration />,
        },
        {
            key: "ml_configuration",
            path: "/controls_admin/ml_configuration",
            component: <MlConfiguration />,
        },
        {
            key: "state_action_matrix",
            path: "/controls_admin/state_action_matrix",
            component: <StateConfiguration />,
        },
        {
            key: "alerts_configuration",
            path: "/controls_admin/alerts_configuration",
            component: <AlertsConfiguration />,
        }
    ];

    return routes;
};

export default ControlsRoute;
