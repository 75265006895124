import Footer from './Footer';

const FooterContainer = ({ onToggle, mobileCollapse = () => {} }) => {
    return (
        <div>
            <Footer onToggle={onToggle} mobileCollapse={mobileCollapse} />
        </div>
    );
};

export default FooterContainer;
