import React from 'react';
import { Col } from 'antd';

const ColumnComponent = ({
    xs = {},
    sm = {},
    md = {},
    lg = {},
    xl = {},
    xxl = {},
    className = '',
    style = {},
    span,
    ...props
}) => {
    return (
        <Col className={className} style={style} xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} span={span}>
            {props.children}
        </Col>
    );
};

export default ColumnComponent;
