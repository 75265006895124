import { useContext } from 'react';
import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Permissions } from "@liveline/ll_node_common";

import './menu.scss';
import { userSelectionActions } from '../../../state/features/userSelectionReducer';
import { AbilityContext } from '../Can';

export const menuItemIsDisabled = (menuItemKey, ability, facilityId) => ability && facilityId ? !Permissions.checkAbility(ability, 'view', menuItemKey, { facilityId }) : false;

const MenuComponent = ({
    theme = 'light',
    style = {},
    mode = 'inline',
    defaultSelectedKeys = [],
    openKeys = [],
    onOpenChange = () => {},
    closeMenu = () => {},
    items = [],
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const ability = useContext(AbilityContext);
    const facilityList = useSelector(x => x?.facilityList);

    return (
        <Menu
            theme={theme}
            style={style}
            onClick={(e) => {
                if (e.key === 'documentation') {
                    navigate(e?.key);
                    window.open('https://customerdocs.liveline.cloud/index.html', '_blank');
                } else if (e.key === 'dashboard') {
                    navigate('/');
                } else if (e.key === 'contact_us') {
                    navigate(e?.key);
                    window.open('https://liveline.atlassian.net/servicedesk/customer/portals', '_blank');
                } else if (e?.key.startsWith('facility_selector')) {
                    const matches = e.key.match(/(?<=facility_selector\/)[\S]*/);

                    if (matches && matches.length) {
                        const id = matches[0];

                        const selectedFacility = facilityList?.data?.find(f => f.facilityId === id);

                        if (selectedFacility) {
                            const currPage = defaultSelectedKeys[0];
                            dispatch(userSelectionActions.setUserSelection({
                                facility: selectedFacility,
                                cell: {},
                                product: {},
                            }))
                            
                            if (menuItemIsDisabled(currPage, ability, id)) {
                                navigate('/');
                            }

                            closeMenu();
                        }

                    }
                } else {
                    navigate(e?.key);
                }
            }}
            mode={mode}
            selectedKeys={defaultSelectedKeys}
            items={items}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
        />
    );
};

export default MenuComponent;
