import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIClient } from "../../services/clientAPI";
import { userSelectionActions } from "./userSelectionReducer";

function createInitialState() {
    const data = JSON.parse(localStorage.getItem(name)) || [];
    return {
        data,
        loading: false,
    };
}

const initLivelineFacilitySelection = (dispatch, selectedFacility) => {
    dispatch(userSelectionActions.setUserSelection(selectedFacility));
}

async function facilityFetch(apiUrl, thunkAPI) {
    if (apiUrl) {    
        const client = new APIClient(apiUrl, thunkAPI.dispatch);

        const response = await client.getAllFacilities();
        let facilities = response?.data?.length
            ? response?.data?.map((facility) => {
                return {
                    id: facility?.id,
                    text: facility?.facilityName,
                    value: facility?.id,
                    label: facility?.facilityName,
                    facilityId: facility?.livelineFacilityId
                };
            })
            : [];

        const user = JSON.parse(localStorage.getItem('user'));

        if (user?.permissions) {
            facilities = facilities.filter(function ({ facilityId }) {
                return user.permissions.findIndex(p => p.facilityId === facilityId) > -1
            })
        }

        localStorage.setItem(name, JSON.stringify(facilities));

        const userSelection = JSON.parse(localStorage.getItem('userSelectionInfo'));

        if (facilities.length && !userSelection?.facility?.livelineId) {
            const {
                id,
                text,
                facilityId,
            } = facilities[0];
    
            const facility = {
                id,
                text,
                livelineId: facilityId,
            };
    
            initLivelineFacilitySelection(thunkAPI.dispatch, { facility });
        }

        return facilities;
    }

    return [];
}

const name = "facilityList";

export const fetchFacilitiesList = createAsyncThunk(`${name}/fetch`, facilityFetch)

export const facilityListReducer = createSlice({
    name,
    initialState: createInitialState(),
    reducers: {
        setFacilityList: (state, action) => {
            // Update Facility List
            return action.payload;
        },
        logout: (state) => {
            state.data = [];
            localStorage.removeItem(name);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFacilitiesList.fulfilled, (state, action) => {
                if (action.payload?.length) {

                    return {
                        loading: false,
                        data: action.payload,
                    };
                }
            })
            .addCase(fetchFacilitiesList.pending, (state) => ({
                loading: true,
                data: state.data,
            }))
            .addCase(fetchFacilitiesList.rejected, () => ({
                loading: false,
                data: [],
            }))
    },
});

// Action creator
export const facilityListActions = facilityListReducer.actions;

export default facilityListReducer.reducer;
