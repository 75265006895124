const fileSortData = (a, b) => {
    try {
        if ((a || a >= 0) && (b || b >= 0)) {
            if (typeof a == 'string' && typeof b == 'string') {
                if (a.toUpperCase() > b.toUpperCase()) {
                    return -1;
                }
                if (a.toUpperCase() < b.toUpperCase()) {
                    return 1;
                }
            } else {
                return a - b;
            }
        }
        return 0;
    } catch (ex) {
        console.error(ex);
        return 0;
    }
};

const orderOption = (order) => {
    if (order === 'ascend' || order === 'asc') {
        return 'asc';
    }
    return 'desc';
};

const selectSortFunction = (optionA, optionB) => {
    const first = Number(optionA);
    const second = Number(optionB);
    if (!isNaN(first) && !isNaN(second)) {
        return first - second;
    }
    return optionA.toLowerCase().localeCompare(optionB.toLowerCase());
};

// Function to sort the Cell name in ascending order-
const cellAscendingSortFunction = (optionA, optionB) => {

    const extractParts = (str) => {
        const match = str.match(/(\d+)([A-Za-z]*)/);
        return { num: parseInt(match[1]), alpha: match[2] || '' };
    };
    
    const aParts = extractParts(optionA);
    const bParts = extractParts(optionB);
    
    // Compare numeric parts first
    if (aParts.num !== bParts.num) {
        return aParts.num - bParts.num;
    }
    
    // If numeric parts are equal, compare alphabetic parts
    return aParts.alpha.localeCompare(bParts.alpha);
};


const mMulti = 60, hMulti = 24;

const inMinutes = value => {
    const unit = value.slice(value.length - 1);
    const numValue = parseInt(value.slice(0, value.length - 1));

    switch (unit) {
        case 'h':
            return numValue * mMulti;
        case 'd':
            return numValue * mMulti * hMulti;
        default:
            return numValue;
    }
};

const inMilliseconds = value => inMinutes(value) * 60000;

const addDash = valueOnlyFlag => valueOnlyFlag ? '' : '-';

const toTimeString = (value, valueOnlyFlag) => { // time in minutes, turned into time string for grafana dashboard url ex. 68 => "-1h-8m" or "1h8m"
    let timeString = '';
    let remainingValue = value;

    const minutes = remainingValue % mMulti;

    if (minutes) timeString = `${addDash(valueOnlyFlag)}${minutes}m` + timeString;
    remainingValue -= minutes;

    if (remainingValue) {
        remainingValue /= mMulti;
        const hours = remainingValue % hMulti;

        if (hours) timeString = `${addDash(valueOnlyFlag)}${hours}h` + timeString;
        remainingValue -= hours;

        if (remainingValue) {
            remainingValue /= hMulti;
            const days = remainingValue;

            if (days) timeString = `${addDash(valueOnlyFlag)}${days}d` + timeString;
        }
    }

    return timeString;
};

const getIndexByKeyValue = (key, val, arr = []) => {
    if (key && val != null) return arr.map((o) => o[key]).indexOf(val);

    return -1;
}

function isEmpty(obj) {
    if (obj) {
        for (const prop in obj) {
            if (Object.hasOwn(obj, prop)) {
                return false;
            }
        }
    }

    return true;
}

function isValiudUrl(url) {
    try {
        new URL(url);
        return true;
    } catch (err) {
        return false;
    }
}

function isString(val) {
    return typeof val === 'string' || val instanceof String;
}

function getCustomerBase(apiURL) {    
    const urlData = new URL(apiURL);

    return `${urlData.protocol}//${urlData.hostname}`
}

function createUserObj(data) {
    return {
        idToken: data?.AuthenticationResult?.IdToken,
        accessToken: data?.AuthenticationResult?.AccessToken,
        // "expiresIn": data?.AuthenticationResult?.ExpiresIn,
        apiURL: data?.apiURL,
        socketURL: data?.socketURL,
        refreshToken: data.refreshToken,
        permissions: data.permissions,
        customerApiBase: getCustomerBase(data.apiURL)
    };
}

function transformUserObj(user) {
    user['customerApiBase'] = getCustomerBase(user.apiURL);

    // if (!window.location.hostname.endsWith(".liveline.cloud")) {
    //     user['apiURL'] = `http://${window.location.hostname}:3003/customer-portal/api/v1`;
    // }
    return user;
}

function findUnique(str) {
    let unique = {};
    for (let char of str) {
        unique[char] = true;
    }
    return Object.keys(unique).join('');
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function getUniqueByKey(key, objArray) {
    const otherVals = [];

    return objArray.filter(function ({ [key]: keyVal }) {
        if (!otherVals.includes(keyVal)) {
            otherVals.push(keyVal);
            return true;
        }

        return false;
    })
}

function count(array, fn = x => x) {
    return array?.filter(fn).length
}

export {
    fileSortData,
    capitalizeFirstLetter,
    transformUserObj,
    createUserObj,
    orderOption,
    selectSortFunction,
    inMinutes,
    toTimeString,
    inMilliseconds,
    getIndexByKeyValue,
    isEmpty,
    isValiudUrl,
    isString,
    findUnique,
    cellAscendingSortFunction,
    getUniqueByKey,
    count,
};
