import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIClient } from "../../services/clientAPI";
import { userSelectionActions } from "./userSelectionReducer";

function createInitialState() {
    const data = JSON.parse(localStorage.getItem(name)) || [];
    return {
        data,
        loading: false,
    };
}

const initLivelineCellSelection = (dispatch, selectedCell) => {
    dispatch(userSelectionActions.setUserSelection(selectedCell));
}

async function cellFetch({
    apiUrl,
    facilityId,
}, thunkAPI) {
    if (apiUrl) {    
        const client = new APIClient(apiUrl, thunkAPI.dispatch);

        const response = await client.getFacilityCells(facilityId);
        const cells = response?.data?.length ? response?.data.map(({
            cellNumber,
            id,
            name,
            facility,
        }) => ({
            cellNumber,
            id,
            name,
            text: name,
            facility_id: facility.id,
        })) : [];

        localStorage.setItem(name, JSON.stringify(cells));

        const userSelection = JSON.parse(localStorage.getItem('userSelectionInfo'));

        if (userSelection) {
            if (cells.length && facilityId !== userSelection.cell?.facility_id) {
                initLivelineCellSelection(thunkAPI.dispatch, { cell: cells[0] });
            }
        }

        return cells;
    }

    return [];
}

const fetchCellsList = createAsyncThunk(
    'cellList/fetch',
    cellFetch
)

export const retrieveCells = async (dispatcher) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const userSelection = JSON.parse(localStorage.getItem('userSelectionInfo'));
    if (user && userSelection?.facility) {
        dispatcher.dispatch(fetchCellsList({
            apiUrl: user.apiURL,
            facilityId: userSelection.facility.id,
        }))
    }
}

const name = "cellList";

export const cellListReducer = createSlice({
    name,
    initialState: createInitialState(),
    reducers: {
        setCellList: (state, action) => {
            // Update Cell List
            return action.payload;
        },
        logout: (state) => {
            state.data = [];
            localStorage.removeItem(name);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCellsList.fulfilled, (state, action) => {
                if (action.payload?.length) {

                    return {
                        loading: false,
                        data: action.payload,
                    };
                }
            })
            .addCase(fetchCellsList.pending, (state) => ({
                loading: true,
                data: state.data,
            }))
            .addCase(fetchCellsList.rejected, () => ({
                loading: false,
                data: [],
            }))
    },
});

// Action creator
export const cellListActions = cellListReducer.actions;

export default cellListReducer.reducer;
