import React from 'react';
import { Row } from 'antd';

const RowComponent = ({ className = '', style = {}, gutter = [24, 24], justify = 'start', align = 'stretch', ...props }) => {
    return (
        <Row gutter={gutter} className={className} justify={justify} align={align} style={style}>
            {props.children}
        </Row>
    );
};

export default RowComponent;
