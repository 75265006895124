import React, { useEffect } from 'react';
import FacilityCell from './FacilityCell';
import { useDispatch, useSelector } from 'react-redux';
import { userSelectionActions } from '../../../state/features/userSelectionReducer';

const FacilityCellContainer = ({
    showCell = false,
    onChangeEvent = () => {},
    currentMenu,
}) => {
    const cellList = useSelector(state => state?.cellList);

    const dispatch = useDispatch();

    const userSelection = useSelector((x) => x?.userSelection);

    useEffect(() => {
        onChangeEvent(userSelection?.facility?.id, userSelection?.cell?.id);
    }, [userSelection]);

    // Cell Handler: sets the state of the cell based on user selection
    const cellHandler = cellName => {
        const cell = cellList?.data?.find(c => c.name === cellName);

        if (cell?.id) {
            dispatch(userSelectionActions.setUserSelection({ cell }))
        }
    }

    return (
        <FacilityCell
            cellHandler={cellHandler}
            selectedCell={userSelection?.cell}
            cellList={cellList}
            currentMenu={currentMenu}
            showCell={showCell}
        />
    );
};

export default FacilityCellContainer;
