import React from 'react';
import { Switch, Form } from 'antd';

const FormSwitchComponent = ({
    name,
    rules,
    className,
    placeholder,
    onClickEvent,
    checked,
    defaultChecked,
    valuePropName,
    disabled
}) => {
    return (
        <Form.Item name={name} rules={rules} className={className} valuePropName={valuePropName}>
            <Switch
                placeholder={placeholder}
                checked={checked}
                onClick={onClickEvent}
                defaultChecked={defaultChecked}
                disabled={disabled}
            />
        </Form.Item>
    );
};

export default FormSwitchComponent;
