import React from 'react';
import { useLocation } from 'react-router-dom';
import './header.scss';
import UserIcon from '../../assets/dark-theme/icons/user-yellow.png';
import logout from '../../assets/dark-theme/icons/Logout.svg';
import { PageRoutesToModule } from '../../components/shared/modules'; // Breadcrumbs
import { useSelector } from 'react-redux';
import { decodeToken } from '../../utils/tokenUtils';
import { ConfigProviderComponent, antdTheme, DropdownMenu } from '../../components/shared';
import { documentation } from '../../assets/dark-theme';
import { CustomerServiceOutlined } from '@ant-design/icons';
import { useSharedContext } from '../../components/shared/ContextProvider';

const baseProductImageClass = 'productIcon svg';

const Header = ({ onLogoutClick = () => {}, onToggle }) => {
    const location = useLocation();
    const user = useSelector((x) => x.auth.user || {});
    const { sharedContext } = useSharedContext();

    const breadCrumbArr = location?.pathname?.split('/') ?? [];
    const breadCrumb = breadCrumbArr?.[breadCrumbArr?.length - 1] ?? 'dashboard';
    const moduleConfig = PageRoutesToModule[breadCrumb];
    let pageTitle = breadCrumb;
    let module = 'basic';
    if (moduleConfig) {
        pageTitle = moduleConfig['title'] + (PageRoutesToModule?.[sharedContext?.currentMenu]?.title ?? '');
        module = moduleConfig['module'];
    }
    const moduleIcon = `${baseProductImageClass} ${module}`;
    let email = '';

    if (user?.idToken) {
        const userData = decodeToken(user?.idToken);
        if (userData?.email) email = userData.email;
    }

    const items = [
        {
            label: (
                <a href='https://customerdocs.liveline.cloud/index.html' target={'_blank'} rel='noreferrer'>
                    <div className='profileMenuItem'>
                        <img src={documentation} alt='documentation' className='menuIcon' />
                        <span className='menuName'> Documentation</span>
                    </div>
                </a>
            ),
            key: '0',
        },
        {
            label: (
                <a
                    href='https://liveline.atlassian.net/servicedesk/customer/portals'
                    target={'_blank'}
                    rel='noreferrer'
                >
                    <div className='profileMenuItem'>
                        <CustomerServiceOutlined style={{ fontSize: 16 }} className='menuIcon' />
                        <span className='menuName'> Contact Us</span>
                    </div>
                </a>
            ),
            key: '1',
        },
        {
            label: (
                <div className='profileMenuItem' onClick={onLogoutClick}>
                    <img src={logout} className='menuIcon' title='logout' alt='user icon' />
                    <span className='menuName'>Logout</span>
                </div>
            ),
            key: '2',
        },
    ];

    return (
        <div className='headerContainer'>
            <div className='headerTitle'>
                {moduleIcon && (
                    <span className={moduleIcon} title={module} onClick={onToggle}>
                        &nbsp;&nbsp;
                    </span>
                )}

                <span className='currentPage'>{`${pageTitle}`}</span>
            </div>
            <div className='userName profileUserName'>{email}</div>
            <div className='headerUserProfile'>
                <div className='userIcon'>
                    <ConfigProviderComponent theme={antdTheme}>
                        <DropdownMenu
                            menu={{
                                items,
                            }}
                            trigger={['click']}
                            placement='bottomRight'
                            arrow
                        >
                            <img src={UserIcon} className='userImg' title={email} alt='user icon' />
                        </DropdownMenu>
                    </ConfigProviderComponent>
                </div>
            </div>
        </div>
    );
};

export default Header;
