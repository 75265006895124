import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIClient } from "../../services/clientAPI";

const name = 'cellServices';
function createInitialState() {
    const data = JSON.parse(localStorage.getItem(name)) || [];
    return {
        data,
        loading: false,
    };
}

export const cellServicesReducer = createSlice({
    name,
    initialState: createInitialState(),
    reducers: {
        setServicesList: (state, action) => {
            // Update Facility List
            return action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchServiceList.fulfilled, (state, action) => {
                if (action.payload?.length) {

                    return {
                        loading: false,
                        data: action.payload,
                    };
                }
            })
            .addCase(fetchServiceList.pending, (state) => ({
                loading: true,
                data: state.data,
            }))
            .addCase(fetchServiceList.rejected, () => ({
                loading: false,
                data: [],
            }))
    },
});


const servicesFetch = async (apiUrl, thunkAPI) => {
    if (apiUrl) {    
        const client = new APIClient(apiUrl, thunkAPI.dispatch);
        const userSelection = JSON.parse(localStorage.getItem('userSelectionInfo'));

        if (userSelection) {
            if (userSelection?.cell?.id) {
                const response = await client.getLDCPServices(userSelection.cell.id);
                const services = response.data;
                thunkAPI.dispatch(cellServicesReducer.actions.setServicesList({ services }));
                return services;
            }
        }
        return [];
    }

    return [];
}

export const fetchServiceList = createAsyncThunk(`${name}/fetchServiceList`, servicesFetch);

export const cellServicesActions = cellServicesReducer.actions;

export default cellServicesReducer.reducer;