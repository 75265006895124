import React, { createContext, useState } from 'react';
import { hourglass } from '../../../assets/dark-theme';
import './loadingOverlay.scss';

export const LoadingContext = createContext();

const LoadingOverlay = ({ children }) => {
    const [loading, setLoading] = useState(false);

    return (
        <LoadingContext.Provider value={{ loading, setLoading }}>
            {
                loading && <div className='loadingWrapper'>
                    <div className='loadingBackground' />
                    <img className='fadeLoading' src={hourglass} alt='Loading' />
                </div>
            }
            {children}
        </LoadingContext.Provider>
    );
};

export default LoadingOverlay;
