import React from 'react';

import { Card } from 'antd';

const CardComponent = ({
    className = '',
    classNameBody = '',
    headStyle = {},
    hoverable = true,
    size = 'small',
    title = '',
    bordered = false,
    ...props
}) => {
    return (
        <Card
            className={className}
            styles={{header: headStyle}}
            hoverable={hoverable}
            size={size}
            title={title}
            bordered={bordered}
        >
            <div className={classNameBody}>{props.children}</div>
            
        </Card>
    );
};

export default CardComponent;
