import React from 'react';
import { Select, Form } from 'antd';

import './formSelect.scss';
const { Option } = Select;
const { Item } = Form;
const FormSelectComponent = ({
    name,
    rules,
    className,
    placeholder,
    dataList = [],
    loading = false,
    optionFilterProp = 'children',
    showSearch = true,
    filterOption = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    filterSort = (optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()),
    handleFormValueschange,
    mode,
    disabled,
    labelInValue = false,
    handleSearch = () => { },
    handleBlur = () => { },
}) => {
    
    return (
        <Item name={name} rules={rules} className={className}>
            <Select
                mode={mode}
                name={name}
                placeholder={placeholder}
                loading={loading}
                filterOption={filterOption}
                filterSort={filterSort}
                showSearch={showSearch}
                optionFilterProp={optionFilterProp}
                onSearch={handleSearch}
                onBlur={handleBlur}
                onChange={(value, option) => handleFormValueschange(({[name]: value}, {[name + 'Id']: option.key}))}
                disabled={disabled}
                labelInValue={labelInValue}
            >
                {dataList?.length &&
                    dataList.map((item) => {
                        return (
                            <Option key={item?.id} value={item?.text}>
                                {item?.text}
                            </Option>
                        );
                    })}
            </Select>
        </Item>
    );
};

export default FormSelectComponent;
